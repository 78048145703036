import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { api_url } from "../Common/Variable";
import NavigationBar from "../Common/Navbar";
import { Button, Modal } from "react-bootstrap";

function Dashboard ()
{
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const [ lang, setLang ] = useState( i18n.language );
	const token = localStorage.getItem( "token" );
	const authenticate = localStorage.getItem( "authenticate" );
	const [ isLoading, setIsLoading ] = useState( true );
	const [ quiz, setQuiz ] = useState( "" );
	const [ resumeQuiz, setResumeQuiz ] = useState( "" );
	const [ nTest, setnTest ] = useState( false );
	const [ rTest, setrTest ] = useState( false );
	const [ attemptBtn, setAttemptBtn ] = useState( false );
	const [checkCredits, setCheckCredits] = useState(null);

	const handleAttemptBtnClose = () =>
	{
		setAttemptBtn( false );
	};

	const handleAttemptBtnShow = () =>
	{
		setAttemptBtn( true );
	};

	const testList = [
		"Personality Test",
		"Emotional Intelligence Test",
		"Visual Reasoning Test",
		"Figure Work Test",
		"Numerical Reasoning Test",
		"Spatial Recognition Test",
		"Three Dimensional Test",
		"Career Test",
		"Core Values Test"
	]

	useEffect( () =>
	{
		setLang( i18n.language );
	}, [ i18n.language ] );

	const handleClick = ( id ) =>
	{
		navigate( `/dashboard/start/${ id }` );
	};

	const handleResumeClick = ( id, attempt, time, title ) =>
	{
		navigate( `/test/${ id }/${ attempt }/${ time }/${ title }/resume` );
	};

	const TestList = async () =>
	{
		var myHeaders = new Headers();
		myHeaders.append( "Accept-Language", lang );
		myHeaders.append( "Authorization", `Bearer ${ token }` );

		var requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};

		fetch( api_url + "get_test_list", requestOptions )
			.then( ( response ) => response.json() )
			.then( ( result ) =>
			{
				setQuiz( result.data );
				setCheckCredits( result.hasTestCredits );
				console.log( "Credits", result.hasTestCredits )
				if ( result.data.length === 0 )
				{
					setnTest( false );
				} else
				{
					setnTest( true );
				}
			} )
			.catch( ( error ) => console.log( "error", error ) );
	};

	const ResumeTest = async () =>
	{
		var myHeaders = new Headers();
		myHeaders.append( "Accept-Language", lang );
		myHeaders.append( "Authorization", `Bearer ${ token }` );

		var requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};

		fetch( api_url + "get_resume_test_list", requestOptions )
			.then( ( response ) => response.json() )
			.then( ( result ) =>
			{
				setResumeQuiz( result.data );
				if ( result.data.length === 0 )
				{
					setrTest( false );
				} else
				{
					setrTest( true );
				}
			} )
			.catch( ( error ) => console.log( "error", error ) );
	};

	useEffect( () =>
	{
		const intervalId = setInterval( () =>
		{
			TestList();
			ResumeTest();
			console.log( "refresh" );
		}, 5000 );

		return () => clearInterval( intervalId );
	}, [ lang ] );

	useEffect( () =>
	{
		if ( !token && !authenticate )
		{
			navigate( "/" );
		} else
		{
			setIsLoading( false );
			TestList();
			ResumeTest();
		}
	}, [ token, authenticate, lang ] );

	if ( isLoading )
	{
		return (
			<div className="text-center">
				<div
					className="d-flex justify-content-center align-items-center"
					style={ { height: "100vh" } }
				>
					<Spinner
						animation="border"
						role="status"
						style={ { width: "5rem", height: "5rem" } }
					></Spinner>
				</div>
			</div>
		);
	}

	return (
		<div>
			<NavigationBar hide={ false } show={ true } />{ " " }
			<div fluid className="dashboard">
				<h1>
					{ t( "career assessment" ) } { t( "tests" ) }
				</h1>
				<div className="tests">
					{ checkCredits === false && (
						<>
							<div className="flex-justify ">
								{
									testList.map( ( n, index ) => (
										<div
											key={ index }
											className="test testList"
										>
											<h3>{ t(n) }</h3>
										</div>
									) )
								}
							</div>
								<Button
								onClick={()=> handleAttemptBtnShow()}
								className="quiz-start-btn btn-attempt"
							>
								{ t( "attempt" ) }
							</Button>
						</>

					) }

					{ checkCredits === true && rTest && resumeQuiz && resumeQuiz.length > 0 ? (

						resumeQuiz.map( ( n, index ) => (
							<div
								key={ index }
								className="test"
								onClick={ () =>
									handleResumeClick(
										n.test_id,
										n.test_credits[ n.test_credits.length - 1 ].attempt_id,
										n[ "allowed_time(min)" ],
										n.test_title
									)
								}
							>
								<h3>{ n.test_title }</h3>{ " " }
								<h4 style={ { color: "orange" } }>
									{ n.test_status === "Not Completed"
										? t( "resumed" )
										: t( "completed" ) }
								</h4>
							</div>
						) )

					) : null }

					{ checkCredits === true && nTest && quiz
						? quiz.length > 0
							? quiz.map( ( n, index ) => (
								<div
									key={ index }
									className="test"
									onClick={ () => handleClick( n.test_id ) }
								>
									<h3>{ n.test_title }</h3>
								</div>
							) )
							: ""
						: "" }
				</div>
			</div>
			<Modal show={ attemptBtn } onHide={ handleAttemptBtnClose } centered>
				<Modal.Title>{t("instructions")}</Modal.Title>
				<Modal.Body className="p-4">
					<p className="fw-light">
						{ t("details 1") }
					</p>
					<p>
						<div className="d-flex">
							<span className="w-25">{ t( "bank name 1" ) }</span>
							<span className="fw-light ms-4">{ t( "bank name 2" ) }</span>
						</div>
						<div className="d-flex">
							<span className="w-25">{ t( "bank title 1" ) }</span>
							<span className="fw-light ms-4">{ t( "bank title 2" ) }</span>
						</div>
						<div className="d-flex">
							<span className="w-25">{ t( "bank iban 1" ) }</span>
							<span className="fw-light ms-4">{ t( "bank iban 2" ) }</span>
						</div>
						<div className="d-flex">
							<span className="w-25">{ t( "bank account 1" ) }</span>
							<span className="fw-light ms-4">{ t( "bank account 2" ) }</span>
						</div>
					</p>
					<p>
						<span className="fw-light">{ t( "details 2" ) }{" "}</span>
						<span dir="ltr" className="me-4">{ t( "details 3" ) }{ " " }</span>
					</p>
					<Button className="my-4" onClick={ () => handleAttemptBtnClose() }>OK</Button>
				</Modal.Body>
			</Modal>
			{/* <Footer /> */ }
		</div>
	);
}
export default Dashboard;
