import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Image, Row, Col } from "react-bootstrap";
import NavigationBar from "../Common/Navbar";
import img1 from "../Resources/Images/1.svg";
import img2 from "../Resources/Images/2.svg";
import img3 from "../Resources/Images/3.svg";
import img4 from "../Resources/Images/4.svg";
import img5 from "../Resources/Images/5.svg";
import img6 from "../Resources/Images/6.svg";
import img7 from "../Resources/Images/7.svg";
import img8 from "../Resources/Images/8.svg";
import img9 from "../Resources/Images/9.svg";
import "animate.css";

function LandingPage() {
	const { t, i18n } = useTranslation();
	const [lang, setLang] = useState(i18n.language);

	useEffect(() => {
		setLang(i18n.language);
	}, [i18n.language]);

	return (
		<div>
			<NavigationBar hide={true} show={false} />
			<div fluid key={lang}>
				<div className="landing">
					<Row>
						<Col md="6">
							<div className="landing-left animate__animated animate__slideInLeft animate__slow">
								<h3>{t("career assessment quiz")}</h3>
								<h1>
									{t("w1")}
									<span>{t("w2")}</span>
								</h1>
								<p className="italic-font">{t("web name")}</p>
							</div>
						</Col>
						<Col md="6">
							<div className="landing-right">
								<div className="img">
									<Image
										src={img1}
										className="img1 animate__animated animate__bounceIn animate__slow"
										fluid
									/>
									<Image
										src={img2}
										className="img2 animate__animated animate__backInDown animate__slow"
										fluid
									/>
									<Image
										src={img7}
										className="img7 animate__animated animate__fadeInTopRight animate__slow"
										fluid
									/>
									<Image
										src={img6}
										className="img6 animate__animated animate__backInDown animate__slow"
										fluid
									/>
									<Image
										src={img5}
										className="img5 animate__animated animate__flipInX animate__slow"
										fluid
									/>
									<Image
										src={img4}
										className="img4 animate__animated animate__rotateInDownLeft animate__slow"
										fluid
									/>
									<Image
										src={img9}
										className="img9 animate__animated animate__backInDown animate__slow"
										fluid
									/>
									<Image
										src={img8}
										className="img8 animate__animated animate__zoomIn animate__slow"
										fluid
									/>
									<Image
										src={img3}
										className="img3 animate__animated animate__backInDown animate__slow"
										fluid
									/>
								</div>
							</div>
						</Col>
					</Row>
				</div>{" "}
				{/* <div className="steps" fluid="sm" id="main">
					{" "}
					<Slide top>
						<h1>{t("steps")}</h1>{" "}
					</Slide>
					<Row className="step-cards">
						<Col md="4">
							<Rotate bottom left>
								<Card className="step-card top-margin">
									<Card.Img variant="top" src={card1} />
									<Card.Body>
										<Card.Title>
											{" "}
											<h2>{t("step 1")}</h2>
										</Card.Title>
										<Card.Text>{t("step 1 desc")}</Card.Text>
									</Card.Body>
								</Card>
							</Rotate>
						</Col>
						<Col md="4">
							<Fade bottom>
								<Card className="step-card second">
									<Card.Img variant="top" src={card2} />
									<Card.Body>
										<Card.Title>
											{" "}
											<h2>{t("step 2")}</h2>
										</Card.Title>
										<Card.Text>{t("step 2 desc")}</Card.Text>
									</Card.Body>
								</Card>{" "}
							</Fade>
						</Col>
						<Col md="4">
							{" "}
							<Rotate bottom right>
								<Card className="step-card top-margin">
									<Card.Img variant="top" src={card3} />
									<Card.Body>
										<Card.Title>
											{" "}
											<h2>{t("step 3")}</h2>
										</Card.Title>
										<Card.Text>{t("step 3 desc")}</Card.Text>
									</Card.Body>
								</Card>
							</Rotate>
						</Col>
					</Row>
				</div> */}
			</div>
			{/* <Footer /> */}
		</div>
	);
}

export default LandingPage;
