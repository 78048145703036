import { Col, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NavigationBar from "../Common/Navbar";

function Profile() {
	const { t, i18n } = useTranslation();
	const token = localStorage.getItem("token");
	const authenticate = localStorage.getItem("authenticate");
	const fatherName = localStorage.getItem("fatherName");
	const Institute = localStorage.getItem("Institute");
	const userName = localStorage.getItem("userName");
	const Gender = localStorage.getItem("Gender");
	const Grade = localStorage.getItem("Grade");
	const Phone = localStorage.getItem("Phone");
	const Email = localStorage.getItem("Email");
	const City = localStorage.getItem("City");
	const Name = localStorage.getItem("Name");
	const Age = localStorage.getItem("Age");
	const [isLoading, setIsLoading] = useState(true);
	const navigate = useNavigate();
	const [lang, setLang] = useState(i18n.language);

	useEffect(() => {
		setLang(i18n.language);
	}, [i18n.language]);
	useEffect(() => {
		if (!token && !authenticate) {
			navigate("/");
		} else {
			setIsLoading(false);
		}
	}, [token, authenticate, lang]);

	if (isLoading) {
		return (
			<div className="text-center">
				<div
					className="d-flex justify-content-center align-items-center"
					style={{ height: "100vh" }}
				>
					<Spinner
						animation="border"
						role="status"
						style={{ width: "5rem", height: "5rem" }}
					></Spinner>
				</div>
			</div>
		);
	}
	return (
		<div>
			<NavigationBar hide={false} show={true} />
			<div fluid className="profile">
				<div>
					<div className="profile-data">
						<Row>
							<Col md={6}>
								<strong>{t("name")}</strong>
							</Col>
							<Col md={6}>{Name}</Col>
						</Row>
						<Row>
							<Col md={6}>
								<strong>{t("userName")}</strong>
							</Col>
							<Col md={6}>{userName}</Col>
						</Row>
						<Row>
							<Col md={6}>
								<strong>{t("fname")}</strong>
							</Col>
							<Col md={6}>{fatherName}</Col>
						</Row>
						<Row>
							<Col md={6}>
								<strong>{t("age")}</strong>
							</Col>
							<Col md={6}>{Age}</Col>
						</Row>
						<Row>
							<Col md={6}>
								<strong>{t("gender")}</strong>
							</Col>
							<Col md={6}>{Gender}</Col>
						</Row>
						<Row>
							<Col md={6}>
								<strong>{t("grade")}</strong>
							</Col>
							<Col md={6}>{Grade}</Col>
						</Row>
						<Row>
							<Col md={6}>
								<strong>{t("institute")}</strong>
							</Col>
							<Col md={6}>{Institute}</Col>
						</Row>
						<Row>
							<Col md={6}>
								<strong>{t("phone")}</strong>
							</Col>
							<Col md={6}>{Phone}</Col>
						</Row>
						<Row>
							<Col md={6}>
								<strong>{t("city")}</strong>
							</Col>
							<Col md={6}>{City}</Col>
						</Row>
						<Row>
							<Col md={6}>
								<strong>{t("email")}</strong>
							</Col>
							<Col md={6}>{Email}</Col>
						</Row>
					</div>
				</div>
			</div>
			{/* <Footer /> */}
		</div>
	);
}
export default Profile;
