import { Button, Container, Spinner, Modal } from "react-bootstrap";
import { useEffect, useState, useRef} from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { api_url } from "../Common/Variable";
import { useTranslation } from "react-i18next";
import NavigationBar from "../Common/Navbar";

function Quiz() {
	const { id, attempt, time, resume, q, title } = useParams();
	const navigate = useNavigate();
	const { t, i18n } = useTranslation();
	const [lang, setLang] = useState(i18n.language);
	const authenticate = localStorage.getItem("authenticate");
	const token = localStorage.getItem("token");
	const [totalQuestions, setTotalQuestions] = useState(parseInt(q) || "");
	const [placeholder, setPlaceholder] = useState(t("write"));
	const [submittedQuestions, setSubmittedQuestions] = useState(0);
	const [selectedOption, setSelectedOption] = useState("");
	const [multipleSelectedOption, setMultipleSelectedOption] = useState([]);
	const [multipleImageSelectedOption, setMultipleImageSelectedOption] =
		useState({
			A: "",
			B: "",
			C: "",
		});
	const [questions, setQuestions] = useState("");
	const [saveTime, setSaveTime] = useState("");
	const [selectionError, setSelectionError] = useState(false);
	const [selectionErrorMsg, setSelectionErrorMsg] = useState("");
	const [spatialError, setSpatialError] = useState(false);
	const [spatialErrorMsg, setSpatialErrorMsg] = useState("");
	const [optionError, setOptionError] = useState(false);
	const [optionErrorMsg, setOptionErrorMsg] = useState("");
	const [arrayShow, setArrayShow] = useState(false);
	const [arrayHide, setArrayHide] = useState(false);
	const [completed, setCompleted] = useState(false);
	const [completedMsg, setCompletedMsg] = useState("");
	const [isLoading, setIsLoading] = useState(true);
	const [qLoading, setQLoading] = useState(false);
	const [progress, setProgress] = useState(0);
	const parsedId = parseInt(id, 10);
	const initialTimeInMinutes = parseInt(time, 10) || 60;
	const initialTimeInSeconds = initialTimeInMinutes * 60;
	const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
	const timeLeftRef = useRef(initialTimeInSeconds);
	const [timeLeft, setTimeLeft] = useState(timeLeftRef.current);
	const hours = Math.floor(timeLeft / 3600);
	const minutes = Math.floor(timeLeft / 60);
	const seconds = timeLeft % 60;
	const [currentStep, setCurrentStep] = useState(0);
	const containerRef = useRef(null);
	const [tenOptions, setTenOptions] = useState("");
	const [fiveOptions, setFiveOptions] = useState("");
	const [userOptions, setUserOptions] = useState(false);

	useEffect(() => {
		if (window.performance && window.performance.navigation.type === 1) {
			if (authenticate && token) {
				navigate( `/test/${ id }/${ attempt }/${ time }/${ title }/resume` );
				if(resume){
					ResumeQuestions( parsedId );
				}
			}
		}
	}, [authenticate, token, navigate]);

	useEffect(() => {
		setPlaceholder(t("write"));
	}, [lang]);

	useEffect(() => {
		setLang(i18n.language);
	}, [i18n.language]);

	useEffect(() => {
		const storedTime = localStorage.getItem(`timerSave_${id}_${attempt}`);
		if (storedTime) {
			const parsedTime = parseInt(storedTime, 10);
			timeLeftRef.current = parsedTime;
			setTimeLeft(parsedTime);
		}
		const timerInterval = setInterval(() => {
			setTimeLeft((prevTime) => {
				if (prevTime > 0) {
					const hours = Math.floor(prevTime / 3600);
					const minutes = Math.floor((prevTime % 3600) / 60);
					const seconds = prevTime % 60;
					const formattedElapsedTime = `${String(hours).padStart(
						2,
						"0"
					)}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
						2,
						"0"
					)}`;

					setSaveTime(formattedElapsedTime);
					localStorage.setItem(
						`timerSave_${id}_${attempt}`,
						prevTime.toString()
					);

					if (hours === 0 && minutes === 0 && seconds === 1) {
						handleCompletedShow(true);
						setCompletedMsg( t("time up"));
						clearInterval( timerInterval );
						EndTime();
					}

					return prevTime - 1;
				} else {
					clearInterval(timerInterval);
					handleCompletedShow( true );
					setCompletedMsg( t( "time up" ) );
					EndTime();
					return 0;
				}
			});
		}, 1000);

		return () => {
			clearInterval(timerInterval);
		};
	}, [id, attempt, lang]);

	useEffect(() => {
		const intervalId = setInterval(() => {
			setSaveTime((prevSaveTime) => {
				SaveTime(prevSaveTime);
				return prevSaveTime;
			});
		}, 10000);

		return () => {
			clearInterval(intervalId);
		};
	}, [lang]);

	useEffect(() => {
		if (!token && !authenticate) {
			navigate("/");
		} else {
			setIsLoading(false);
			if (!resume) {
				Questions(parsedId);
			} else {
				ResumeQuestions(parsedId);
			}
		}
	}, [token, authenticate, totalQuestions, lang]);

	useEffect(() => {
		setSelectedOption("");
	}, [currentQuestionIndex]);

	useEffect(() => {
		setMultipleImageSelectedOption({
			A: null,
			B: null,
			C: null,
		});
	}, [currentQuestionIndex]);

	const scrollContainerIntoView = () => {
		const currentStepElement =
			containerRef.current.querySelector(".step.current");

		if (currentStepElement) {
			const containerRect = containerRef.current.getBoundingClientRect();
			const stepRect = currentStepElement.getBoundingClientRect();

			const scrollAmount =
				stepRect.left -
				containerRect.left -
				(containerRect.width - stepRect.width) / 2;

			containerRef.current.scrollTo({
				left: containerRef.current.scrollLeft + scrollAmount,
				behavior: "smooth",
			});
		}
	};

	const handleCompletedClose = () => {
		setCompleted(false);
	};

	const handleCompletedShow = () => {
		setCompleted(true);
	};

	const EndTime = async () =>
	{
		const myHeaders = new Headers();
		myHeaders.append("Accept-Language", "en");
		myHeaders.append("Authorization", `Bearer ${token}`);

		const formdata = new FormData();
		formdata.append("attempt_id", attempt);

		const requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: formdata,
			redirect: "follow",
		};

		fetch(api_url + "end_test_time", requestOptions)
			.then((response) => response.json())
			.then( ( result ) =>
			{
				console.log(result ); 
			})
			.catch((error) => console.error(error));
	}

	const SaveTime = async (currentSaveTime) => {
		var myHeaders = new Headers();
		myHeaders.append("Accept-Language", lang);
		myHeaders.append("Authorization", `Bearer ${token}`);

		var formdata = new FormData();
		formdata.append("elapsed_time", currentSaveTime);
		formdata.append("attempt_id", attempt);
		formdata.append("test_id", parsedId);

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: formdata,
			redirect: "follow",
		};

		fetch(api_url + "store_timer_current_time", requestOptions)
			.then((response) => response.json())
			.then( ( result ) =>
			{
				console.log(result);
			})
			.catch((error) => console.log("error", error));
	};

	const Questions = (t_id) => {
		var myHeaders = new Headers();
		myHeaders.append("Accept-Language", lang);
		myHeaders.append("Authorization", `Bearer ${token}`);

		var formdata = new FormData();
		formdata.append("test_id", t_id);

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: formdata,
			redirect: "follow",
		};

		fetch(api_url + "get_test_questions_list", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log(result);
				if (result.data[0].options.length > 20) {
					setTenOptions(result.data[0].options);
				} else if ( result.data[ 0 ].options.length === 10 )
				{
					setTenOptions("");
					setFiveOptions( result.data[0].options );
				}else {
					setUserOptions( true );
				}
				setQuestions(result.data);
				if (Array.isArray(result.data[0].options[0].value)) {
					setArrayShow(true);
				} else {
					setArrayHide(true);
				}
			})
			.catch((error) => console.log("error", error));
	};

	const ResumeQuestions = (t_id) => {
		var myHeaders = new Headers();
		myHeaders.append("Accept-Language", lang);
		myHeaders.append("Authorization", `Bearer ${token}`);

		var formdata = new FormData();
		formdata.append("test_id", t_id);
		formdata.append("attempt_id", attempt);

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: formdata,
			redirect: "follow",
		};

		fetch(api_url + "resume_test", requestOptions)
			.then((response) => response.json())
			.then( ( result ) =>
			{
				console.log(result.data);
				if (result.data[0].options.length > 20) {
					setTenOptions(result.data[0].options);
				} else if ( result.data[ 0 ].options.length === 10 )
				{
					setTenOptions( "" );
					setFiveOptions( result.data[ 0 ].options );
				} else
				{
					setUserOptions( true );
				}
				const totalQuestions = parseInt(result.total_questions);
				setQuestions(result.data);
				setTotalQuestions(totalQuestions);
				setSubmittedQuestions(result.submitted_questions);
				if (Array.isArray(result.data[0].options[0].value)) {
					setArrayShow(true);
				} else {
					setArrayHide(true);
				}
			})
			.catch((error) => console.log("error", error));
	};

	const SubmitAnswer = (q_id) => {
		setQLoading(true);
		var myHeaders = new Headers();
		myHeaders.append("Accept-Language", lang);
		myHeaders.append("Authorization", `Bearer ${token}`);

		var formdata = new FormData();
		formdata.append("attempt_id", attempt);
		formdata.append("question_id", q_id);
		formdata.append("option_id", selectedOption);
		formdata.append("test_id", parsedId);

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: formdata,
			redirect: "follow",
		};

		fetch(api_url + "submit_question", requestOptions)
			.then((response) => response.json())
			.then( ( result ) =>
			{
				console.log( result );
				if (result.message === "Question Submitted Successfully") {
					const newProgress =
						((currentQuestionIndex + 1) / questions.length) * 100;
					setProgress( newProgress );
					setSaveTime((prevSaveTime) => {
						SaveTime(prevSaveTime);
						return prevSaveTime;
					} );
					console.log("questions total", totalQuestions);
					console.log(
						"questions total",
						parseInt(currentQuestionIndex) + 1 + parseInt(submittedQuestions) === parseInt(totalQuestions)
					);
					console.log("current q", parseInt(currentQuestionIndex) + 1 + parseInt(submittedQuestions));
					console.log("sub q", parseInt(submittedQuestions, 10));

					if (parseInt(currentQuestionIndex) + 1 + parseInt(submittedQuestions) < parseInt(totalQuestions)) {
						setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
					} else if (
						parseInt(currentQuestionIndex) + 1 + parseInt(submittedQuestions) ===
						totalQuestions
					) {
						handleCompletedShow( true );
						setCompletedMsg( t("quiz completed") );
						console.log("completed");
					}
					setSelectionError(false);
					scrollContainerIntoView();
					setCurrentStep((prevStep) => prevStep + 1);
					setOptionError( false );
					setQLoading(false);
				} else {
					setOptionError( true );
					setOptionErrorMsg( result.message );
					setQLoading(false);
				}
			})
			.catch((error) => {
				console.log("error", error);
				setOptionError( true );
				setOptionErrorMsg( error );
				setQLoading(false);
			});
	};

	const SubmitMultipleImageAnswer = (q_id) => {
		setQLoading(true);
		var myHeaders = new Headers();
		myHeaders.append("Accept-Language", lang);
		myHeaders.append("Authorization", `Bearer ${token}`);

		var formdata = new FormData();
		formdata.append("attempt_id", attempt);
		formdata.append("question_id", q_id);
		formdata.append("multiple_answers[]", multipleImageSelectedOption.A);
		formdata.append("multiple_answers[]", multipleImageSelectedOption.B);
		formdata.append("multiple_answers[]", multipleImageSelectedOption.C);

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: formdata,
			redirect: "follow",
		};

		fetch(api_url + "submit_question", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log(result);
				if (result.status === 1) {
					const newProgress =
						((currentQuestionIndex + 1) / questions.length) * 100;
					setProgress(newProgress);
					setSaveTime((prevSaveTime) => {
						SaveTime(prevSaveTime);
						return prevSaveTime;
					});
					console.log("questions total", totalQuestions);
					console.log(
						"questions total",
						parseInt(currentQuestionIndex) + 1 + parseInt(submittedQuestions) === parseInt(totalQuestions)
					);
					console.log(
						"current q",
						(parseInt(currentQuestionIndex) + 1 + parseInt(submittedQuestions))
					);
					console.log("sub q", parseInt(submittedQuestions, 10));

					if (parseInt(currentQuestionIndex) + 1 + parseInt(submittedQuestions) < parseInt(totalQuestions)) {
						setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
					} else if (
						parseInt(currentQuestionIndex) + 1 + parseInt(submittedQuestions) ===
						totalQuestions
					) {
						handleCompletedShow( true );
						setCompletedMsg( t("quiz completed") );
						console.log("completed");
					}
					setSelectionError(false);
					scrollContainerIntoView();
					setCurrentStep((prevStep) => prevStep + 1);
					setOptionError(false);
					setQLoading(false);
				} else {
					setOptionError(true);
					setOptionErrorMsg( result.message );
					setQLoading(false);
				}
			})
			.catch((error) => {
				console.log("error", error);
				setOptionError(true);
				setOptionErrorMsg( error );
				setQLoading(false);
			});
	};

	const SubmitMultipleAnswer = ( q_id ) =>
	{
		setQLoading(true);
		var myHeaders = new Headers();
		myHeaders.append("Accept-Language", lang);
		myHeaders.append("Authorization", `Bearer ${token}`);

		var formdata = new FormData();
		formdata.append("attempt_id", attempt);
		formdata.append("question_id", q_id);
		multipleSelectedOption.forEach((option, index) => {
			formdata.append("multiple_answers[]", option);
		});

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: formdata,
			redirect: "follow",
		};

		fetch(api_url + "submit_question", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log(result);
				if ( result.status === 1 && result.data) {
					const newProgress =
						((currentQuestionIndex + 1) / questions.length) * 100;
					setProgress(newProgress);
					setSaveTime((prevSaveTime) => {
						SaveTime(prevSaveTime);
						return prevSaveTime;
					});
					console.log("questions total", totalQuestions);
					console.log(
						"questions total",
						(parseInt(currentQuestionIndex) + 1 + parseInt(submittedQuestions) === parseInt(totalQuestions))
					);
					console.log(
						"current q",
						parseInt(currentQuestionIndex + 1 + submittedQuestions, 10)
					);
					console.log("sub q", parseInt(submittedQuestions, 10));
					

					if (parseInt(currentQuestionIndex) + 1 + parseInt(submittedQuestions) < parseInt(totalQuestions)) {
							setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
						setTenOptions( result.data );
					} else if (
						parseInt(currentQuestionIndex) + 1 + parseInt(submittedQuestions) ===
						totalQuestions
					) {
						handleCompletedShow( true );
						setCompletedMsg( t("quiz completed") );
						console.log("completed");
					}
					setSelectionError(false);
					scrollContainerIntoView();
					setCurrentStep((prevStep) => prevStep + 1);
					setOptionError(false);
					setQLoading(false);
					setMultipleSelectedOption([]);
				} else if ( result.status === 1 )
				{
					const newProgress =
						((currentQuestionIndex + 1) / questions.length) * 100;
					setProgress(newProgress);
					setSaveTime((prevSaveTime) => {
						SaveTime(prevSaveTime);
						return prevSaveTime;
					});
					console.log("questions total", totalQuestions);
					console.log(
						"questions total",
						parseInt(currentQuestionIndex, 10) + 1 + parseInt(submittedQuestions, 10) === parseInt(totalQuestions, 10)
					);
					console.log(
						"current q",
						parseInt(currentQuestionIndex, 10) + 1 + parseInt(submittedQuestions, 10)
					);
					console.log("sub q", parseInt(submittedQuestions, 10));

					handleCompletedShow(true);
					setCompletedMsg( t("quiz completed") );
					console.log("completed");
					setSelectionError(false);
					scrollContainerIntoView();
					setCurrentStep((prevStep) => prevStep + 1);
					setOptionError(false);
					setQLoading(false);
					setMultipleSelectedOption([]);
				} else {
					setOptionError(true);
					setOptionErrorMsg( result.message );
					setQLoading(false);
					console.log("questions total", totalQuestions);
					console.log(
						"questions total",
						parseInt(currentQuestionIndex) + 1 + parseInt(submittedQuestions) === parseInt(totalQuestions)
					);
					console.log(
						"current q",
						parseInt(currentQuestionIndex, 10) + 1 + parseInt(submittedQuestions, 10)
					);
					console.log("sub q", parseInt(submittedQuestions, 10));
				}
			})
			.catch((error) => {
				console.log("error", error);
				setOptionError(true);
				setOptionErrorMsg( error );
				setQLoading(false);
			});
	};

	const SubmitMultipleSpatialAnswer = (q_id) => {
		setQLoading(true);
		var myHeaders = new Headers();
		myHeaders.append("Accept-Language", lang);
		myHeaders.append("Authorization", `Bearer ${token}`);

		var formdata = new FormData();
		formdata.append("attempt_id", attempt);
		formdata.append("question_id", q_id);
		multipleSelectedOption.forEach((option, index) => {
			formdata.append("multiple_answers[]", option);
		});

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: formdata,
			redirect: "follow",
		};

		fetch(api_url + "submit_question", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log(result);
				if ( result.status === 1 ) {
					const newProgress =
						((currentQuestionIndex + 1) / questions.length) * 100;
					setProgress( newProgress );
					setSaveTime((prevSaveTime) => {
						SaveTime(prevSaveTime);
						return prevSaveTime;
					});
					console.log("questions total", totalQuestions);
					console.log(
						"questions total",
						parseInt(currentQuestionIndex, 10) + 1 + parseInt(submittedQuestions, 10) === parseInt(totalQuestions, 10)
					);
					console.log(
						"current q",
						parseInt(currentQuestionIndex + 1 + submittedQuestions, 10)
					);
					console.log("sub q", parseInt(submittedQuestions, 10));

					setMultipleSelectedOption([]);
					setCurrentStep((prevStep) => prevStep + 1);
					if (parseInt(currentQuestionIndex) + 1 + parseInt(submittedQuestions) < parseInt(totalQuestions)) {
						setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
					} else if (
						parseInt(currentQuestionIndex) + 1 + parseInt(submittedQuestions) ===
						totalQuestions
					) {
						handleCompletedShow( true );
						setCompletedMsg( t("quiz completed") );
					}
					setSpatialError(false);
					scrollContainerIntoView();
					setQLoading(false);
					setOptionError(false);
				} else {
					setOptionError(true);
					setOptionErrorMsg( result.message );
					setQLoading(false);
				}
			})
			.catch((error) => {
				console.log("error", error);
				setOptionError(true);
				setOptionErrorMsg( error );
				setQLoading(false);
			});
	};

	const SubmitAnswerUserInput = (q_id) => {
		setQLoading(true);
		var myHeaders = new Headers();
		myHeaders.append("Accept-Language", lang);
		myHeaders.append("Authorization", `Bearer ${token}`);

		var formdata = new FormData();
		formdata.append("attempt_id", attempt);
		formdata.append("question_id", q_id);
		formdata.append("user_answer", selectedOption);

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: formdata,
			redirect: "follow",
		};

		fetch(api_url + "submit_question", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log(result);
				if ( result.status === 1 ) {
					const newProgress =
						((currentQuestionIndex + 1) / questions.length) * 100;
					setProgress( newProgress );
					setSaveTime( ( prevSaveTime ) =>
					{
						SaveTime(prevSaveTime);
						return prevSaveTime;
					});
					console.log("questions total", totalQuestions);
					console.log(
						"questions total",
						parseInt(currentQuestionIndex) + 1 + parseInt(submittedQuestions) === parseInt(totalQuestions)
					);
					console.log(
						"current q",
						parseInt(currentQuestionIndex + 1 + submittedQuestions, 10)
					);
					console.log("sub q", parseInt(submittedQuestions, 10));

					setSelectedOption("");
					if (parseInt(currentQuestionIndex) + 1 + parseInt(submittedQuestions) < parseInt(totalQuestions)) {
						setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
					} else if (
						parseInt(currentQuestionIndex) + 1 + parseInt(submittedQuestions) ===
						totalQuestions
					) {
						handleCompletedShow( true );
						setCompletedMsg( t("quiz completed") );
					}
					setSelectionError(false);
					scrollContainerIntoView();
					setCurrentStep((prevStep) => prevStep + 1);
					setOptionError(false);
					setQLoading(false);
				} else {
					setOptionError(true);
					setOptionErrorMsg( result.message );
					setQLoading(false);
				}
			})
			.catch((error) => {
				console.log("error", error);
				setOptionError(true);
				setOptionErrorMsg( error );
				setQLoading(false);
			});
	};

	const handleMultipleImageNext = (id) => {
		if (
			!multipleImageSelectedOption.A ||
			!multipleImageSelectedOption.B ||
			!multipleImageSelectedOption.C
		) {
			setSelectionError(true);
			return;
		} else {
			SubmitMultipleImageAnswer(id);
		}
	};

	const handleMultipleAnsNext = (id) => {
		if (multipleSelectedOption.length === 0) {
			setSelectionError(true);
		} else {
			SubmitMultipleAnswer( id );

		}
	};

	const handleMultipleSpatialAnsNext = (id) => {
		if (
			multipleSelectedOption.includes(undefined) ||
			multipleSelectedOption.includes("empty") ||
			multipleSelectedOption.length < 5
		) {
			setSpatialError(true);
		} else {
			SubmitMultipleSpatialAnswer(id);
		}
	};

	const handleNext = (id) => {
		if (!selectedOption) {
			setSelectionError(true);
			return;
		} else {
			SubmitAnswer(id);
		}
	};

	const handleNextUserInput = (id) => {
		if (!selectedOption) {
			setSelectionError(true);
			return;
		} else {
			SubmitAnswerUserInput(id);
		}
	};

	const handleCompletedQuiz = () => {
		navigate("/dashboard");
	};

	const handleOptionSelection = (index, value) => {
		setMultipleSelectedOption((prevOptions) => {
			const updatedOptions = [...prevOptions];
			updatedOptions[index] = value;
			return updatedOptions;
		});
	};

	if (questions.length === 0) {
		return <p className="loading">{t("loading")}</p>;
	}

	const currentQuestion = questions[currentQuestionIndex];
	const stepsArray = Array.from(
		{ length: totalQuestions },
		(_, index) => index + 1
	);

	if (isLoading) {
		return (
			<div className="text-center">
				<div
					className="d-flex justify-content-center align-items-center"
					style={{ height: "100vh" }}
				>
					<Spinner
						animation="border"
						role="status"
						style={{ width: "5rem", height: "5rem" }}
					></Spinner>
				</div>
			</div>
		);
	}
	return (
		<div>
			<NavigationBar hide={false} show={true} sticky={true} />
			<Container fluid className="quiz">
				<div className="main">
					{/* //!Progress Bar */}
					<div className="arrow-steps clearfix" ref={containerRef}>
						{stepsArray.map((step, index) => (
							<div
								key={index}
								className={`step ${
									currentStep === parseInt(index) - parseInt(submittedQuestions)
										? "current"
										: currentStep > parseInt(index) - parseInt(submittedQuestions)
										? "done"
										: ""
								}`}
								style={{ width: `${100 / stepsArray.length}%` }}
							></div>
						))}
					</div>{" "}
					<h2 className="question-title">{title}</h2>
					<h2 className="question-num">
						{parseInt(currentQuestionIndex, 10) + 1 + parseInt(submittedQuestions, 10)}/{totalQuestions}
					</h2>
					<div className="timer">
						<div className="time">
							<span className="number">{String(hours).padStart(2, "0")}</span>
							<span className="time-text">{t("hour")}</span>
						</div>
						<div className="time">:</div>
						<div className="time">
							<span className="number">{String(minutes).padStart(2, "0")}</span>
							<span className="time-text">{t("min")}</span>
						</div>
						<div className="time">:</div>
						<div className="time">
							<span className="number">{String(seconds).padStart(2, "0")}</span>
							<span className="time-text">{t("sec")}</span>
						</div>
					</div>{" "}
					{selectionError && <h3 className="error">{t("selection error")}</h3>}
					{ optionError && <h3 className="error">{ optionErrorMsg }</h3>}
					{spatialError && (
						<h3 className="spatial-error">{t("spatial error")}</h3>
					)}
					{id == 6 ? (
						<div className="row">
							<div className="col-md-5">
								<img
									className="spatial-image-questions"
									src={currentQuestion.question_title}
								/>
							</div>

							<div className="col-md-7 spatial-images-answers">
								{currentQuestion.options.map(
									(option, index) =>
										option.type === "Image" &&
										!Array.isArray(option.value) && (
											<div key={index}>
												<div className="selection-options">
													<img src={option.value} alt={`Option ${index + 1}`} />
												</div>
												<div className="row">
													<label
														className={`col-md-6 yes ${
															multipleSelectedOption[index] === "Y"
																? "checked"
																: ""
														}`}
													>
														<input
															type="radio"
															name={`option-${index}`}
															value="Y"
															onChange={() => handleOptionSelection(index, "Y")}
															checked={multipleSelectedOption[index] === "Y"}
														/>
														<span>
															<svg
																xmlns="http://www.w3.org/2000/svg"
																width="24"
																height="24"
																fill="currentColor"
																className="bi bi-check2"
																viewBox="0 0 16 16"
															>
																<path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0" />
															</svg>{" "}
															{t("yes")}
														</span>
													</label>
													<label
														className={`col-md-6 no ${
															multipleSelectedOption[index] === "N"
																? "checked"
																: ""
														}`}
													>
														<input
															type="radio"
															name={`option-${index}`}
															value="N"
															onChange={() => handleOptionSelection(index, "N")}
															checked={multipleSelectedOption[index] === "N"}
														/>
														<span>
															<svg
																xmlns="http://www.w3.org/2000/svg"
																width="16"
																height="16"
																fill="currentColor"
																className="bi bi-x-lg"
																viewBox="0 0 16 16"
															>
																<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
															</svg>{" "}
															{t("no")}
														</span>
													</label>
												</div>
											</div>
										)
								)}
								<Button
									className="spatial-img-ans-btn"
									onClick={() => {
										handleMultipleSpatialAnsNext(currentQuestion.question_id);
									}}
									disabled={qLoading}
								>
									{qLoading ? (
										<Spinner
											animation="border"
											role="status"
											style={{ width: "2rem", height: "2rem" }}
										/>
									) : currentQuestionIndex === questions.length - 1 ? (
										t("submit")
									) : (
										t("next")
									)}
								</Button>
							</div>
						</div>
					) : (
						<div>
							{/* //!Text Question */}
							{currentQuestion.question_type === "Text" && (
								<p
									className="questions-index-text"
									style={{ whiteSpace: "pre-line" }}
									dangerouslySetInnerHTML={{
										__html: currentQuestion.question_title,
									}}
								></p>
							)}
							{/* //!Image Question */}
							{currentQuestion.question_type === "Image Path" && (
								<div>
									<img
										className="image-questions"
										src={currentQuestion.question_title}
									/>
								</div>
							)}
							{/* //!Text Answer */}
							{currentQuestion.options.length < 9 &&
								currentQuestion.options.length > 0 &&
								currentQuestion.question_type === "Text" &&
								currentQuestion.options[0].type !== "Image" && (
									<div>
										<div className="texts-answers">
											{currentQuestion.options.map((option, index) => (
												<div
													key={index}
													className={`selection-options ${
														selectedOption === option.id.toString()
															? "checked"
															: ""
													}`}
												>
													<label>
														<input
															type="radio"
															name={`option-${currentQuestionIndex}`}
															value={option.id}
															onChange={(e) => {
																setSelectedOption(e.target.value);
															}}
															required
															checked={selectedOption === option.id.toString()}
														/>
														<span>{`${option.value}`}</span>
													</label>
												</div>
											))}
										</div>
										<Button
											className="text-ans-btn"
											onClick={() => {
												handleNext(currentQuestion.question_id);
											}}
											disabled={qLoading}
										>
											{qLoading ? (
												<Spinner
													animation="border"
													role="status"
													style={{ width: "2rem", height: "2rem" }}
												/>
											) : currentQuestionIndex === questions.length - 1 ? (
												t("submit")
											) : (
												t("next")
											)}
										</Button>
									</div>
								)}
							{/* //!Image Answer */}
							{arrayHide &&
								currentQuestion.options.length > 0 &&
								currentQuestion.options[0].type === "Image" && (
									<div>
										<div className="images-answers">
											{currentQuestion.options.map(
												(option, index) =>
													option.type === "Image" &&
													!Array.isArray(option.value) && (
														<div key={index}>
															<div
																className={`selection-options ${
																	selectedOption === option.id.toString()
																		? "checked"
																		: ""
																}`}
															>
																<label>
																	<input
																		type="radio"
																		name={`option-${currentQuestionIndex}`}
																		value={option.id}
																		onChange={(e) => {
																			setSelectedOption(e.target.value);
																		}}
																		required
																		checked={
																			selectedOption === option.id.toString()
																		}
																	/>
																	<span>
																		<div>
																			<img src={option.value} />
																		</div>
																	</span>
																</label>
															</div>
														</div>
													)
											)}
										</div>
										<Button
											className={
												currentQuestion.question_type === "Text"
													? "text-ans-btn"
													: "img-ans-btn"
											}
											onClick={() => {
												handleNext(currentQuestion.question_id);
											}}
											disabled={qLoading}
										>
											{qLoading ? (
												<Spinner
													animation="border"
													role="status"
													style={{ width: "2rem", height: "2rem" }}
												/>
											) : currentQuestionIndex === questions.length - 1 ? (
												t("submit")
											) : (
												t("next")
											)}
										</Button>
									</div>
								)}
							{/* //!Multiple Image Answer */}
							{arrayShow && currentQuestion.options.length > 0 && (
								<div>
									<div className="images-answers">
										{currentQuestion.options.map(
											(option, index) =>
												option.type === "Image" &&
												Array.isArray(option.value) && (
													<div key={index}>
														<div className="mul-image-answer">
															<strong
																className="selection-options-strong"
																style={{ color: "black" }}
															>
																Side&nbsp;
																{String.fromCharCode(65 + index)}{" "}
															</strong>
															{option.value.map((radioOption, radioIndex) => (
																<div key={radioIndex}>
																	<div
																		className={`selection-options ${
																			multipleImageSelectedOption[
																				String.fromCharCode(65 + index)
																			] ===
																			radioIndex + 1
																				? "checked"
																				: ""
																		}`}
																	>
																		<label>
																			<input
																				type="radio"
																				name={`radio-option-${String.fromCharCode(
																					65 + index
																				)}`}
																				value={radioIndex + 1}
																				onChange={(e) => {
																					const selectedValue = radioIndex + 1;
																					const sideKey = String.fromCharCode(
																						65 + index
																					);

																					setMultipleImageSelectedOption(
																						(prevOptions) => ({
																							...prevOptions,
																							[sideKey]: selectedValue,
																						})
																					);

																					setSelectedOption(selectedValue);
																				}}
																				required
																				checked={
																					multipleImageSelectedOption[
																						String.fromCharCode(65 + index)
																					] ===
																					radioIndex + 1
																				}
																			/>
																			<span>
																				<div>
																					<img src={radioOption} />
																				</div>
																			</span>
																		</label>
																	</div>
																</div>
															))}
														</div>
													</div>
												)
										)}
									</div>
									<Button
										className="mul-img-ans-btn"
										onClick={() => {
											handleMultipleImageNext(currentQuestion.question_id);
										}}
										disabled={qLoading}
									>
										{qLoading ? (
											<Spinner
												animation="border"
												role="status"
												style={{ width: "2rem", height: "2rem" }}
											/>
										) : currentQuestionIndex === questions.length - 1 ? (
											t("submit")
										) : (
											t("next")
										)}
									</Button>
								</div>
							)}{" "}
							{/* //!Multiple Text Answer*/}
							{tenOptions && (
								<div>
									<div className="checks-answers">
										{tenOptions.map((option, index) => (
											<div key={index}>
												<div
													className={`selection-options ${
														multipleSelectedOption.includes(
															(index + 1).toString()
														)
															? "checked"
															: ""
													}`}
												>
													<label>
														<input
															type="checkbox"
															name={`option`}
															value={index + 1}
															onChange={(e) => {
																const isChecked = e.target.checked;
																const value = e.target.value;
																setMultipleSelectedOption((prevOptions) => {
																	if (isChecked) {
																		if (tenOptions.length === 10) {
																			if (prevOptions.length < 5) {
																				return [...prevOptions, value];
																			}
																		} else {
																			if (prevOptions.length < 10) {
																				return [...prevOptions, value];
																			}
																		}
																	} else {
																		return prevOptions.filter(
																			(selectedValue) => selectedValue !== value
																		);
																	}
																	return prevOptions;
																});
															}}
															required
															checked={multipleSelectedOption.includes(
																(index + 1).toString()
															)}
														/>
														<span>
															{multipleSelectedOption.indexOf(
																(index + 1).toString()
															) > -1
																? `${
																		multipleSelectedOption.indexOf(
																			(index + 1).toString()
																		) + 1
																  }`
																: ""}
															{` ${option.value}`}{" "}
														</span>
													</label>
												</div>
											</div>
										))}
									</div>
									<Button
										className="text-ans-btn"
										onClick={() => {
											handleMultipleAnsNext(currentQuestion.question_id);
										}}
										disabled={qLoading}
									>
										{qLoading ? (
											<Spinner
												animation="border"
												role="status"
												style={{ width: "2rem", height: "2rem" }}
											/>
										) : currentQuestionIndex === questions.length - 1 ? (
											t("submit")
										) : (
											t("next")
										)}
									</Button>
								</div>
							)}

							{fiveOptions && (
								<div>
									<div className="checks-answers">
										{ fiveOptions.map( ( option, index ) => (
											<div key={ index }>
												<div
													className={ `selection-options ${ multipleSelectedOption.includes(
														( index + 1 ).toString()
													)
															? "checked"
															: ""
														}` }
												>
													<label>
														<input
															type="checkbox"
															name={ `option` }
															value={ index + 1 }
															onChange={ ( e ) =>
															{
																const isChecked = e.target.checked;
																const value = e.target.value;
																setMultipleSelectedOption( ( prevOptions ) =>
																{
																	if ( isChecked )
																	{
																		if ( fiveOptions.length === 5 )
																		{
																			if ( prevOptions.length < 5 )
																			{
																				return [ ...prevOptions, value ];
																			}
																		} else
																		{
																			if ( prevOptions.length < 5 )
																			{
																				return [ ...prevOptions, value ];
																			}
																		}
																	} else
																	{
																		return prevOptions.filter(
																			( selectedValue ) => selectedValue !== value
																		);
																	}
																	return prevOptions;
																} );
															} }
															required
															checked={ multipleSelectedOption.includes(
																( index + 1 ).toString()
															) }
														/>
														<span>
															{ multipleSelectedOption.indexOf(
																( index + 1 ).toString()
															) > -1
																? `${ multipleSelectedOption.indexOf(
																	( index + 1 ).toString()
																) + 1
																}`
																: "" }
															{ ` ${ option.value }` }{ " " }
														</span>
													</label>
												</div>
											</div>
										) ) }
									</div>
									<Button
										className="text-ans-btn"
										onClick={ () =>
										{
											handleMultipleAnsNext( currentQuestion.question_id );
										} }
										disabled={ qLoading }
									>
										{ qLoading ? (
											<Spinner
												animation="border"
												role="status"
												style={ { width: "2rem", height: "2rem" } }
											/>
										) : currentQuestionIndex === questions.length - 1 ? (
											t( "submit" )
										) : (
											t( "next" )
										) }
									</Button>
								</div>
							) }
							{/* //!User Answer */}
							{userOptions && currentQuestion.options.length === 0 &&
								currentQuestion.question_type === "Text" &&
								id !== 9 && (
									<div className="input-answers">
										<input
											value={selectedOption}
											type="text"
											placeholder={placeholder}
											onChange={(e) => {
												setSelectedOption(e.target.value);
											}}
										/>
										<Button
											className="text-ans-btn"
											onClick={() => {
												handleNextUserInput(currentQuestion.question_id);
											}}
											disabled={qLoading}
										>
											{qLoading ? (
												<Spinner
													animation="border"
													role="status"
													style={{ width: "2rem", height: "2rem" }}
												/>
											) : currentQuestionIndex === questions.length - 1 ? (
												t("submit")
											) : (
												t("next")
											)}
										</Button>{" "}
									</div>
								)}
						</div>
					)}
				</div>
			</Container>
			<Modal show={completed} onHide={handleCompletedClose} centered>
				<Modal.Title>{completedMsg}</Modal.Title>
				<Modal.Body>
					<Button onClick={handleCompletedQuiz}>OK</Button>
				</Modal.Body>
			</Modal>
		</div>
	);
}

export default Quiz;
